const modifyEmailHtmlForPreview = (bodyValue) => {
  tempDiv = document.createElement('div');
  tempDiv.innerHTML = bodyValue;

  let figures = tempDiv.getElementsByTagName('figure');
  for(let figure of figures) {
    let table = figure.querySelector('table');
    
    if (!table) {
      continue;
    }
    figure.classList.remove('table')
    figure.classList.add('richtext_table_figure')

    figure.style.height = figure.style.height || '250px';
    figure.style.width = figure.style.width || '300px';
    table.style.height = figure.style.height
    table.style.width = figure.style.width
  };

  for(let figure of figures) {
    let img = figure.querySelector('img');

    if (!img) {
      continue;
    };

    if (!figure.classList.contains('image-style-align-right') && !figure.classList.contains('image-style-align-left')) {
      figure.classList.add('image-style-align-center');
    };

    if (!figure.style.width) {
      figure.style.width = '50%';
    }
    img.style.width = '100%';
  };

  let tables = tempDiv.getElementsByTagName('table');
  for(let table of tables) {
    table.style.borderStyle = table.style.borderStyle || 'solid';
    table.style.borderColor = table.style.borderColor || '#000';
    table.style.borderWidth = table.style.borderWidth || '1px';
  };

  let cells = tempDiv.getElementsByTagName('td');
  for(let cell of cells) {
    cell.style.borderStyle = cell.style.borderStyle || 'solid';
    cell.style.borderColor = cell.style.borderColor || '#000';
    cell.style.borderWidth = cell.style.borderWidth || '1px';

    cell.style.textAlign = cell.style.textAlign || 'center';
    cell.style.verticalAlign = cell.style.verticalAlign || 'middle';
    cell.style.padding = cell.style.padding || '10px';
  };

  return tempDiv.innerHTML;
}

module.exports = { modifyEmailHtmlForPreview };
